import React, { Component } from "react";
import Group from "./Group";
import "./SubjectRow.css";

const COLORS = ["purple", "pink", "blue", "orange", "red", "green", "yellow"];

class SubjectRow extends Component {
	constructor(props) {
		super(props);
		this.scroller = React.createRef();
		this.title = React.createRef();
		let groupsPerPage = window.innerWidth <= 700 ? 2 : 4; // mobile : desktop
		let maxPage = Math.floor((Object.keys(props.groups).length - 1) / groupsPerPage);
		let colors = this.randomColors(Object.keys(props.groups).length);
		this.state = {
			page: 0,
			maxPage,
			groupsPerPage,
			colors,
		};

		window.addEventListener("resize", (e) => {
			const amountOfGroups = Object.keys(this.props.groups).length;
			// in case on mobile, we shouldn't readjust the pages
			if (!window.matchMedia("(pointer: coarse)").matches) {
				// Check if resize changed the amount of courses displayed per page
				if (window.innerWidth > 700 && this.state.groupsPerPage === 2) {
					this.setState({
						page: Math.floor(this.state.page / 2),
						groupsPerPage: 4,
						maxPage: Math.floor((amountOfGroups - 1) / 4),
					});
				} else if (window.innerWidth <= 700 && this.state.groupsPerPage === 4) {
					let page = this.state.page * 2;
					this.setState({
						page: page,
						groupsPerPage: 2,
						maxPage: Math.floor((amountOfGroups - 1) / 2),
					});
				}
				this.scrollToPage(this.state.page);
			}
		});
	}

	randomColors(n) {
		let res = [COLORS[Math.floor(Math.random() * 7)]];
		for (let i = 1; i < n; i++) {
			let colors = COLORS.slice();
			colors.splice(colors.indexOf(res[res.length - 1]), 1); // remove last color from pool
			res.push(colors[Math.floor(Math.random() * 6)]);
		}
		return res;
	}

	scrollToPage(page) {
		let scroller = this.scroller.current;
		const margin = this.title.current.offsetLeft;
		scroller.scrollTo({
			left: scroller.children[this.state.groupsPerPage * page].offsetLeft - margin,
			behavior: "smooth",
		});
		this.setState({
			page: page,
		});
	}

	getSortedGroups(groups) {
		// course objects -> filtered and ordered groups
		return Object.keys(groups)
			.map((id) => groups[id])
			.sort((a, b) => {
				let startYear = new RegExp(/(\d{4})\//); // '2019/2020' => 2019
				let yearA = parseInt(a.schoolYear.match(startYear)[1]);
				let yearB = parseInt(b.schoolYear.match(startYear)[1]);
				return (
					parseInt(`${yearA}${a.period}${a.description.charCodeAt(0)}`) -
					parseInt(`${yearB}${b.period}${b.description.charCodeAt(0)}`)
				);
			});
	}

	render() {
		const { groups, selectedGroup } = this.props;
		const { page, maxPage, colors } = this.state;
		return (
			<div className="SubjectRow">
				<h3
					className="title"
					ref={this.title}
				>
					{this.props.title.charAt(0).toUpperCase() + this.props.title.slice(1)}
				</h3>
				{page !== 0 && (
					<div
						className="scroll-button left"
						onClick={() => this.scrollToPage(page - 1)}
					/>
				)}
				<div
					className="scroller"
					ref={this.scroller}
				>
					{this.getSortedGroups(groups).map(({ id, name, courses, type }, i) => {
						return (
							<Group
								key={id}
								type={type}
								groupId={id}
								courseId={Object.values(courses)[0]?.id}
								onClick={(_) => this.props.onClick(id)}
								text={name}
								selected={selectedGroup === id}
								large={this.props.large}
								color={colors[i]}
							/>
						);
					})}
				</div>
				{page !== maxPage && (
					<div
						className="scroll-button right"
						onClick={() => this.scrollToPage(page + 1)}
					/>
				)}
			</div>
		);
	}
}

export default SubjectRow;
